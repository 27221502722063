.sticky-footer-promo-container {
    position: fixed;
    left: 0;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    width: 100%;
    text-align: center;
    z-index: 1000;
}
  
.sticky-footer-promo-top {
    transform: translateY(-100%);
    top: 0;
}
  
.sticky-footer-promo-bottom {
    transform: translateY(100%);
    bottom: 0;
}
  
.sticky-footer-promo-content {
    width: 100%;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0;
    text-decoration: none !important;
}

.sticky-footer-promo-content > h2 {
    font-size: 15px;
    font-weight: 800;
}

.sticky-footer-promo-content > h3 {
    font-size: 13px;
    font-weight: 600;
}

.sticky-footer-promo-content > h4 {
    font-size: 12px;
    font-weight: 500;
}
  
.sticky-footer-promo {
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
}
  
.sticky-footer-promo-show {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    transform: translateY(0%);
    opacity: 1;
}
  
.sticky-footer-promo-hidden {
    opacity: 0;
    padding: 0;
    visibility: hidden;
    opacity: 0%;
    transition: all ease-in-out 0.3s;
}
  
.sticky-footer-promo-dismiss-button {
    border: none;
    background: transparent;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 5px;
}
  
.sticky-footer-promo-dismiss:hover {
    background: rgba(255, 255, 255, 0.15);
}
  
.sticky-footer-promo-dismiss:focus {
    box-shadow: inset 0 0 0 2px var(--border-base);
}
  
.sticky-footer-promo-image > img {
    max-height: 30px;
    width: auto;
}
  
.sticky-footer-promo-image {
    display: flex;
    align-items: center;
    padding-right: 15px;
}
  
.sticky-footer-promo-title {
    font-size: 15px;
    margin: 0;
}
  
.sticky-footer-promo-button {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: 15px;
}
  
.sticky-footer-promo-button-content {
    cursor: pointer;
    border: none;
    border-radius: 20px;
    white-space: nowrap;
    align-items: center;
    padding: 3px 8px;
    font-weight: bold;
}
  
.sticky-footer-promo-content-text {
    font-size: 12px;
    margin: 2px 0 0 0;
}

@media (max-width: 800px) {
    .sticky-footer-promo-content {
        flex-direction: column;
        gap: 0;
        width: 50vw;
        padding-left: 4px;
    }
    
    .sticky-footer-promo-image {
        padding-left: 20px;
    }
                  
    .sticky-footer-promo-show {
        height: 55px;
        justify-content: space-between;
    }
                  
    .sticky-footer-promo-button {
        padding-right: 4vw;
        margin-left: 3vw;
        
    }
                  
    .sticky-footer-promo-dismiss {
        width: 35px;
        height: 35px;
    }
                          
    .sticky-footer-promo-content-text {
        line-height: 12px;
        font-size: 10px;
    }
    
    .sticky-footer-promo-button-content {
        min-width: 20vw;
        height: 35px;
        font-size: 14px;
        font-weight: 600;
    }
    
    .sticky-footer-promo-content > h2 {
        font-size: 16px;
    }
    
    .sticky-footer-promo-content > h3 {
        font-size: 14px;
    }
    
    .sticky-footer-promo-content > h4 {
        font-size: 12px;
    }
}

@media (max-width: 600px) {
    .sticky-footer-promo-image {
        display: none;
    }

    .sticky-footer-promo-button-content {
        font-size: 12px;
    }
    
    .sticky-footer-promo-content > h2 {
        font-size: 14px;
    }
    
    .sticky-footer-promo-content > h3 {
        font-size: 12px;
    }
    
    .sticky-footer-promo-content > h4 {
        font-size: 10px;
    }
}
